<template>
  <div>
    <!-- List physical objects -->
    <BaseList
      :list-query="objects"
      route="object"
      :name-map="nameMap"
      class="physical-object-list"
      locale-section="pages.physicalObjects"
      :unknown-name="$t('pages.artefacts.unknown')"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "PhysicalObjectList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  props: {
    signum1: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      objects: {
        query: gql`
          query physicalObjects($signum1: String) {
            list: physicalObjects(signum1: $signum1) {
              artefact
              material
              extant
              atOriginalLocation
              artefact
              artefacts {
                id
              }
              datings {
                dating
              }
              tpq
              taq
              id
              location
              originalLocation
              standardSigna {
                signum1
                signum2
              }
            }
          }
        `,
        variables: { signum1: this.signum1 }
      }
    };
  },
  methods: {
    nameMap(element) {
      return {
        name:
          "(" +
          element.standardSigna
            .map((signum) => signum.signum1 + " " + signum.signum2)
            .join(", ") +
          ") " +
          (element.artefact || this.$t("pages.artefacts.unknown")) +
          " " +
          this.$t("pages.physicalObjects.of") +
          " " +
          (element.material
            ? element.material.toLowerCase()
            : this.$t("pages.materials.unknown")),
        ...element
      };
    }
  }
};
</script>
